.app {
  font-family: 'Montserrat';
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.escape {
  text-decoration: none;
  background: #4A5759;
  color: white;
  width: 50px;
  height: 50px;
  font-size: 2rem;
  position: absolute;
  top: calc(100vh - 200px);
  right: 100px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

.app header {
  background-color: white;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.app header img {
  height: 80px;
  width: auto;
}

.app header .menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
}

.app header .menu a {
  margin-left: 20px;
}

.app footer {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.app footer > a {
  margin: 20px;
}

.app-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #F6F7FB;
}

.welcome {
  padding: 20px;
  max-width: 800px;
}

.welcome h1 {
  position: relative;
}

.welcome h1::before{
  content: '';
  background-image: url(./images/2.svg);
  background-size: contain;
  background-repeat: no-repeat;
  position: relative; 
  height: 100px;
  width: 100px;
  position: absolute;
  z-index: 1;
  left: -100px;
  bottom: -50px;
}

.welcome h1::after{
  content: '';
  background-image: url(./images/3.svg);
  background-size: contain;
  background-repeat: no-repeat;
  position: relative; 
  height: 80px;
  width: 80px;
  position: absolute;
  z-index: 1;
  top: 0;
  right: -100px;
}

.welcome h2 {
  margin-top: 50px;
}

.welcome img {
  width: 40px;
  height: auto;
  margin: 5px;
  z-index: -1;
}

.welcome .get-button {
  text-decoration: none;
  border: 1px solid #4A5759;
  margin: 20px 0px;
  border-radius: 20px;
  padding: 10px 20px;
  color: white;
  background-color: #4A5759;
}

@media (max-width: 640px) {
  h1::before{
    content: none;
    background-image: none;
  }
  
  h1::after{
    content: none;
    background-image: none;
  }
}